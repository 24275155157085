





















import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import FolderList from "../../components/folder-list.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    FolderList,
  },
})
export default class Name extends Vue {
  private data: any = [
    [
      {
        title: "描述性分析",
        type: "描述性分析",
        description:
          "主要包括数据的频数分析、数据的集中趋势分析、数据离散程度分析、数据的分布、以及一些基本的统计图形，常用的指标有平均值、中位数、四分位数，方差、标准差，标准分等。",
        img: "/analysis/定性特征.png",
        isTese: false,
      },
      {
        title: "差异性分析",
        type: "差异性分析",
        description:
          "比较独立样本、配对样本组间的差异，支持t检验、秩和检验、Pearson卡方检验、Fisher精确概率法等统计学检验方法。",
        img: "/analysis/颜色特征.png",
        isTese: false,
      },
      {
        title: "回归分析",
        type: "回归分析",
        description:
          "分析多个因素对因变量的影响程度，通常用于预测分析，时间序列模型以及发现变量之间的因果关系，支持简单线性回归、多重线性回归、逻辑回归。",
        img: "/analysis/几何特征.png",
        isTese: false,
      },
    ],
    [
      {
        title: "相关性分析",
        type: "相关性分析",
        description:
          "对两个或多个具备相关性的变量元素进行分析，从而衡量两个变量因素的相关密切程度，支持Pearson、Phi、列联系数、Eta2等相关分析方法。",
        img: "/analysis/纹理特征.png",
        isTese: false,
      },
      {
        title: "聚类分析",
        type: "聚类分析",
        description:
          "聚类能够作为一个独立的工具获得数据的分布状况，观察每一簇数据的特征，集中对特定的聚簇集合作进一步地分析，常用的聚类分析方法有K-means、dbscan等。",
        img: "/analysis/舌图演变.png",
        isTese: false,
      },
      {
        title: "关联规则分析",
        type: "关联规则分析",
        description:
          "反映一个事物与其他事物之间的相互依存性和关联性，用于从大量数据中挖掘出有价值的数据项之间的相关关系，支持Apriori关联规则等。",
        img: "/analysis/颜色特征.png",
        isTese: false,
      },
    ],
  ];
  private goToResult(item: any) {
    this.$router.push({
      path: "/main/analysis/tongji/tongji-result",
      query: {
        type: item,
      },
    });
  }
}
